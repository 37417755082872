import React from "react";
import Header from '../components/Header';

const Work=()=>{

    return(
        <Header>
            <h1>
                Your work Here
            </h1>
        </Header>
    )
};

export default Work;
