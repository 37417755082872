import React from "react";

const CrossTeamCollabDetails=()=>{
    return(
        <div>
            <h1>Cross team collabration</h1>
            <h1>Templates will update soon</h1>
        </div>
    )
};

export default CrossTeamCollabDetails;