import React from "react";

const ProductDiscoveryDetails=()=>{

    return(
        <div>
            <h1>Product discovery</h1>
            <h1>Templates will update soon</h1>
        </div>
    )
}

export default ProductDiscoveryDetails;