import React from "react";

const ProductRoadmapDetails=()=>{
    return(
        <div>
            <h1>
                Production road
            </h1>
            <h1>Templates will update soon</h1>
        </div>
    )
};

export default ProductRoadmapDetails;