import React from 'react';

const IdeaPrioritizationDetails=()=>{
    return(
        <div>
            <h1>idea prioritize</h1>
            <h1>Templates will update soon</h1>
        </div>
    )
};

export default IdeaPrioritizationDetails;